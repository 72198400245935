.table-datagrid {
  .MuiDataGrid-columnHeader {
    background-color: #323582;
    color: #ffffff;
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .MuiDataGrid-cell {
    color: #2c3850;
    font-size: 16px;
    line-height: 24px;
  }

  .MuiCheckbox-root, .MuiIconButton-root {
    color: #dddddd;
  }

  .MuiDataGrid-cell:focus {
    outline: none !important;
  }

  .Mui-selected {
    background-color: #f2f2f2 !important;
  }

  .MuiDataGrid-cell:focus-within {
    outline: 0;
  }

  // .MuiDataGrid-cell{
  //   cursor: pointer;
  // }
}
